function setCookie(cname, cvalue, exdays = 0) {
  var date, expires;
  if (exdays) {
    date = new Date();
    date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
    expires = ' expires=' + date.toGMTString();
  }
  document.cookie = cname + '=' + cvalue + '; path=/;' + expires;
}

function hasCookieVal(cname, val) {
  if (document.cookie.indexOf(cname+'='+val) >= 0) {
    return true;
  }
  return false;
}

function hasCookie(cname) {
  if (hasCookieVal(cname, 'true')) {
		return true;
	}
	return false;
}

function activateVideo(object, single = false) {
  if (single) {
    var videourl = object.attr('href') + '&autoplay=1';
    object.parent().siblings('iframe').attr('src', videourl);
  } else {
    object.each(function() {
      var videourl = $(this).attr('href');
      $(this).parent().siblings('iframe').attr('src', videourl);
    });
  }
	object.parent().siblings('picture').hide();
  object.parent().siblings('img').hide();
  object.parent().hide();
}

function checkCookie() {
  if (hasCookie('youtube-active')) {
    activateVideo($('a.youtube-start'));
  }
}

$('.youtube-active').click(function() {
  if ($(this).is(':checked')) {
    setCookie('youtube-active', 'true', 30);
		activateVideo($('a.youtube-start'));
  }
});

$('a.youtube-start').click(function(e) {
  e.preventDefault();
  activateVideo($(this), true);
});

$(document).ready(function() {
  checkCookie();
});
